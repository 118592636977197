<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <Spinner :spin="!isLoaded"/>
    <!-- Header -->
    <section class="header activity_history">
      <!-- Left -->
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="goBack"
        >
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">獲獎紀錄</span>
      <!-- Right -->
      <div class="flexH width right"></div>
    </section>
    <!-- Main -->
    <section class="main activity_history">
      <div
        class="flexH width between padding"
        v-for="(record, index) in records"
        :key="'record' + index"
      >
        <div class="flexV">
          <span class="fL">{{ record.campaignName }}</span>
          <span class="fM gray">{{ tsToDatetime(record.rewardedAt) }}</span>
        </div>
        <div class="flexV center" v-if="record.rewardType == 'points'">
          <img v-if="Number(record.rewardContext.amount)" src="@/assets/icon/point.svg" />
          <span v-if="Number(record.rewardContext.amount)" class="fM">{{ record.rewardContext.amount }}點</span>
        </div>
        <div class="flexV center" v-else-if="record.rewardType == 'coupons'">
          <img src="@/assets/icon/ticket.svg" />
          <span class="fM">x{{ record.rewardContext.amount }}</span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';

export default {
  name: "CampaignRecords",
  components: {
    Spinner
  },
  beforeRouteEnter(to, from, next) {
      to.meta.from = from;
      next();
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      records: [],
      isLoaded: false,
    };
  },
  mounted() {
    this.getCampaignRecords();
  },
  computed: {
    ...mapState([
      "user",
      "userInfo"
    ]),
  },
  methods: {
    dummyRecords() {
      let result = [
        {
          rewardedAt: 1654499339,
          rewardId: 20,
          rewardName: "註冊禮100點",
          rewardType: "points",
          rewardContext: {
            name: "100點",
            amount: 100,
          },
          campaignId: "M_p5O76qR854",
          campaignName: "註冊任務",
          campaignDesc: "成功註冊桃園市民卡即可獲得100點",
          missionId: 22,
          missionName: "會員註冊任務",
          missionDesc: "只要成功註冊會員即可獲得，每人限領一次",
        },
        {
          rewardedAt: 1654672465,
          rewardId: 65,
          rewardName: "九折優惠券",
          rewardType: "coupons",
          rewardContext: {
            couponId: "coupon_id",
            amount: 1,
          },
          campaignId: "M_r5PDKD0m58",
          campaignName: "PrePOB測試任務：抽獎小遊戲",
          campaignDesc: "As title",
          missionId: 72,
          missionName: "抽獎小遊戲 測試2",
          missionDesc: "好禮大方送...",
        },
        {
          rewardedAt: 1654672465,
          rewardId: 66,
          rewardName: "測試店家集章卡-5集章",
          rewardType: "stamps",
          rewardContext: {
            amount: 5,
            stampCardId: 1,
          },
          campaignId: "M_r5PDKD0m58",
          campaignName: "PrePOB測試任務：抽獎小遊戲",
          campaignDesc: "As title",
          missionId: 72,
          missionName: "抽獎小遊戲 測試2",
          missionDesc: "好禮大方送...",
        },
        {
          rewardedAt: 1654672465,
          rewardId: 67,
          rewardName: "Tesla Model 3乙台",
          rewardType: "misc",
          rewardContext: {},
          campaignId: "M_r5PDKD0m58",
          campaignName: "PrePOB測試任務：抽獎小遊戲",
          campaignDesc: "As title",
          missionId: 72,
          missionName: "抽獎小遊戲 測試2",
          missionDesc: "好禮大方送...",
        },
      ];
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetCamapignRecords() {
      var config = {
        method: "get",
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/reward-records/${this.user.userId}`,
        headers: {},
      };

      return this.$http(config);
    },
    getCampaignRecords() {
      this.callGetCamapignRecords()
      // this.dummyRecords()
      .then((res) => {
        this.records = res.data;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.isLoaded = true;
      });
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    goBack(){
      this.$router.push(this.$route.meta.from.path);
      // if (window.history.length > 2) {
      //   this.$router.go(-1)
      // }else {
      //   this.$router.push('/campaign');
      // }
    }
  },
};
</script>
